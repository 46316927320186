import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-1ee4e10c0c7e1bd5ff9cd70d1f3c03521abf21ea7ee45aa92deab16f87af4dbb.js"
          async
        />
        <p id="skip-link">
          <a href="#main-menu" className="element-invisible element-focusable">
            Jump to navigation
          </a>
        </p>
        <div id="mobile-sidebar">
          <div className="mobile-logo-wrapper">
            <a href="/" title="Inicio" rel="home" className="mobile-logo">
              <img
                src="/sites/all/themes/custom/iniston_zen/logo.png"
                alt="Inicio"
              />
            </a>
          </div>
          <div className="region region-mobile-sidebar">
            <div
              id="block-search-form"
              className="block block-search first odd"
              role="search"
            >
              
            </div>
            <div
              id="block-system-main-menu"
              className="block block-system block-menu last even"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-expanded first expanded">
                  <a href="/la-tos/" className="menu__link">
                    La tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a href="/la-tos/que-es-la-tos/" className="menu__link">
                        ¿Qué es la tos?
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="menu__link"
                          >
                            Definición
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/que-es-la-tos/vivencia/"
                            className="menu__link"
                          >
                            Vivencia
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/que-es-la-tos/funcionamiento/"
                            className="menu__link"
                          >
                            Funcionamiento
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/tipos-de-tos/seca/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/tipos-de-tos/con-mocos/"
                            className="menu__link"
                          >
                            Tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                        className="menu__link"
                      >
                        Momentos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="menu__link"
                          >
                            Gripe
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/nocturna/"
                            className="menu__link"
                          >
                            Noche
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/alergia/"
                            className="menu__link"
                          >
                            Alergia
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/verano/"
                            className="menu__link"
                          >
                            Verano
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/la-tos/cuando-tienes-tos/fumador/"
                            className="menu__link"
                          >
                            Fumador
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a
                        href="/la-tos/como-aliviar-la-tos/"
                        className="menu__link"
                      >
                        Cómo aliviar la tos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/remedios/" className="menu__link">
                    Remedios contra la tos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-leaf first leaf">
                      <a
                        href="/remedios/caseros-y-naturales/"
                        className="menu__link"
                      >
                        Remedios caseros
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/remedios/tos-nocturna/" className="menu__link">
                        Tos nocturna
                      </a>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/remedios/contra-la-tos/seca/"
                        className="menu__link"
                      >
                        Recetas contra la tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="menu__link"
                          >
                            Remedios tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/remedios/contra-la-tos/mocos/"
                            className="menu__link"
                          >
                            Remedios tos con moco
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-expanded expanded">
                  <a href="/jarabe-iniston/" className="menu__link">
                    Productos
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/jarabe-iniston/expectorante-descongestivo/"
                        className="menu__link"
                      >
                        Adultos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="menu__link"
                          >
                            Iniston expectorante y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/expectorante/"
                            className="menu__link"
                          >
                            Iniston expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo-descongestivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo y descongestivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/jarabe-iniston/antitusivo/"
                            className="menu__link"
                          >
                            Iniston antitusivo
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-iniston/mucolitico/"
                            className="menu__link"
                          >
                            Iniston mucolítico
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded last expanded">
                      <a
                        href="/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Niños
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Instolin pediátrico expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin pediátrico antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu__item is-active-trail is-expanded last expanded active-trail">
                  <a
                    href="/tos-ninos/"
                    className="menu__link is-active-trail active-trail"
                  >
                    Tos en niños
                  </a>
                  <ul className="menu">
                    <li className="menu__item is-expanded first expanded">
                      <a
                        href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                        className="menu__link"
                      >
                        Tipos de tos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="menu__link"
                          >
                            Tos seca
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                            className="menu__link"
                          >
                            Tos con mocos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                        className="menu__link"
                      >
                        Productos
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="menu__link"
                          >
                            Inistolin expectorante
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                            className="menu__link"
                          >
                            Inistolin antitusivo
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-active-trail is-leaf leaf active-trail">
                      <a
                        href="/tos-ninos/remedios/"
                        className="menu__link is-active-trail active-trail active"
                      >
                        Remedios para la tos en niños
                      </a>
                    </li>
                    <li className="menu__item is-leaf leaf">
                      <a href="/tos-ninos/causas/" className="menu__link">
                        Causas
                      </a>
                    </li>
                    <li className="menu__item is-expanded expanded">
                      <a
                        href="/tos-ninos/desarrollo-infantil/7-anos/"
                        className="menu__link"
                      >
                        Desarrollo infantil
                      </a>
                      <ul className="menu">
                        <li className="menu__item is-leaf first leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="menu__link"
                          >
                            7 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/8-anos/"
                            className="menu__link"
                          >
                            8 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/9-anos/"
                            className="menu__link"
                          >
                            9 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/10-anos/"
                            className="menu__link"
                          >
                            10 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/11-anos/"
                            className="menu__link"
                          >
                            11 años
                          </a>
                        </li>
                        <li className="menu__item is-leaf last leaf">
                          <a
                            href="/tos-ninos/desarrollo-infantil/12-anos/"
                            className="menu__link"
                          >
                            12 años
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu__item is-leaf last leaf">
                      <a href="/dibujos-para-colorear/" className="menu__link">
                        Diviértete con Pandolín
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="page">
          <div className="header-wrapper">
            <header className="header" id="header" role="banner">
              <div className="header-top">
                <div className="header-top-left">
                  <span id="mobile-button" />
                </div>
                <a
                  href="/"
                  title="Inicio"
                  rel="home"
                  className="header__logo"
                  id="logo"
                >
                  <img
                    src="/sites/all/themes/custom/iniston_zen/logo.png"
                    alt="Inicio"
                    className="header__logo-image"
                  />
                </a>
                <div className="region region-pre-header">
                  <div
                    id="block-search-form--2"
                    className="block block-search first odd"
                    role="search"
                  >
                    
                  </div>
                  <div
                    id="block-block-3"
                    className="block block-block last even"
                  >
                    <p>
                    <img 
                      alt="" 
                      className="attr__format__media_original img__fid__142 img__view_mode__media_original media-image header-map-pin" 
                      src="/sites/default/files/marker.png"
                    />
                      <strong>Dónde comprar Iniston</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="header__region region region-header">
                <div
                  id="block-superfish-1"
                  className="block block-superfish first last odd"
                >
                  <ul
                    id="superfish-1"
                    className="menu sf-menu sf-main-menu sf-horizontal sf-style-none sf-total-items-4 sf-parent-items-4 sf-single-items-0"
                  >
                    <li
                      id="menu-376-1"
                      className="first sf-item-1 sf-depth-1 sf-total-children-4 sf-parent-children-3 sf-single-children-1 menuparent"
                    >
                      <a href="/la-tos/" className="sf-depth-1 menuparent">
                        La tos
                      </a>
                      <ul>
                        <li
                          id="menu-382-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-3 sf-parent-children-0 sf-single-children-3 menuparent"
                        >
                          <a
                            href="/la-tos/que-es-la-tos/"
                            className="sf-depth-2 menuparent"
                          >
                            ¿Qué es la tos?
                          </a>
                          <ul>
                            <li
                              id="menu-555-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/"
                                className="sf-depth-3"
                              >
                                Definición
                              </a>
                            </li>
                            <li
                              id="menu-540-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/vivencia/"
                                className="sf-depth-3"
                              >
                                Vivencia
                              </a>
                            </li>
                            <li
                              id="menu-541-1"
                              className="last sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/que-es-la-tos/funcionamiento/"
                                className="sf-depth-3"
                              >
                                Funcionamiento
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-460-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/la-tos/tipos-de-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-461-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/seca/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-462-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/tipos-de-tos/con-mocos/"
                                className="sf-depth-3"
                              >
                                Tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-467-1"
                          className="middle sf-item-3 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                            className="sf-depth-2 menuparent"
                          >
                            Momentos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-470-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/con-gripe-resfriado/"
                                className="sf-depth-3"
                              >
                                Gripe
                              </a>
                            </li>
                            <li
                              id="menu-472-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/nocturna/"
                                className="sf-depth-3"
                              >
                                Noche
                              </a>
                            </li>
                            <li
                              id="menu-474-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/alergia/"
                                className="sf-depth-3"
                              >
                                Alergia
                              </a>
                            </li>
                            <li
                              id="menu-476-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/verano/"
                                className="sf-depth-3"
                              >
                                Verano
                              </a>
                            </li>
                            <li
                              id="menu-478-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/la-tos/cuando-tienes-tos/fumador/"
                                className="sf-depth-3"
                              >
                                Fumador
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-466-1"
                          className="last sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/la-tos/como-aliviar-la-tos/"
                            className="sf-depth-2"
                          >
                            Cómo aliviar la tos
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-377-1"
                      className="middle sf-item-2 sf-depth-1 sf-total-children-3 sf-parent-children-1 sf-single-children-2 menuparent"
                    >
                      <a href="/remedios/" className="sf-depth-1 menuparent">
                        Remedios contra la tos
                      </a>
                      <ul>
                        <li
                          id="menu-479-1"
                          className="first sf-item-1 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/caseros-y-naturales/"
                            className="sf-depth-2"
                          >
                            Remedios caseros
                          </a>
                        </li>
                        <li
                          id="menu-480-1"
                          className="middle sf-item-2 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/remedios/tos-nocturna/"
                            className="sf-depth-2"
                          >
                            Tos nocturna
                          </a>
                        </li>
                        <li
                          id="menu-454-1"
                          className="last sf-item-3 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/remedios/contra-la-tos/seca/"
                            className="sf-depth-2 menuparent"
                          >
                            Recetas contra la tos
                          </a>
                          <ul>
                            <li
                              id="menu-458-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/seca/"
                                className="sf-depth-3"
                              >
                                Remedios tos seca
                              </a>
                            </li>
                            <li
                              id="menu-459-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/remedios/contra-la-tos/mocos/"
                                className="sf-depth-3"
                              >
                                Remedios tos con moco
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-378-1"
                      className="middle sf-item-3 sf-depth-1 sf-total-children-2 sf-parent-children-2 sf-single-children-0 menuparent"
                    >
                      <a
                        href="/jarabe-iniston/"
                        className="sf-depth-1 menuparent"
                      >
                        Productos
                      </a>
                      <ul>
                        <li
                          id="menu-449-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-5 sf-parent-children-0 sf-single-children-5 menuparent"
                        >
                          <a
                            href="/jarabe-iniston/expectorante-descongestivo/"
                            className="sf-depth-2 menuparent"
                          >
                            Adultos
                          </a>
                          <ul>
                            <li
                              id="menu-447-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-446-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/expectorante/"
                                className="sf-depth-3"
                              >
                                Iniston expectorante
                              </a>
                            </li>
                            <li
                              id="menu-445-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo-descongestivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo y descongestivo
                              </a>
                            </li>
                            <li
                              id="menu-444-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/antitusivo/"
                                className="sf-depth-3"
                              >
                                Iniston antitusivo
                              </a>
                            </li>
                            <li
                              id="menu-443-1"
                              className="last sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-iniston/mucolitico/"
                                className="sf-depth-3"
                              >
                                Iniston mucolítico
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-451-1"
                          className="last sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Niños
                          </a>
                          <ul>
                            <li
                              id="menu-442-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Instolin pediátrico expectorante
                              </a>
                            </li>
                            <li
                              id="menu-441-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin pediátrico antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-379-1"
                      className="active-trail last sf-item-4 sf-depth-1 sf-total-children-6 sf-parent-children-3 sf-single-children-3 menuparent"
                    >
                      <a href="/tos-ninos/" className="sf-depth-1 menuparent">
                        Tos en niños
                      </a>
                      <ul>
                        <li
                          id="menu-482-1"
                          className="first sf-item-1 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                            className="sf-depth-2 menuparent"
                          >
                            Tipos de tos
                          </a>
                          <ul>
                            <li
                              id="menu-485-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                className="sf-depth-3"
                              >
                                Tos seca
                              </a>
                            </li>
                            <li
                              id="menu-484-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/"
                                className="sf-depth-3"
                              >
                                Tos con mocos
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-546-1"
                          className="middle sf-item-2 sf-depth-2 sf-total-children-2 sf-parent-children-0 sf-single-children-2 menuparent"
                        >
                          <a
                            href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                            className="sf-depth-2 menuparent"
                          >
                            Productos
                          </a>
                          <ul>
                            <li
                              id="menu-553-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-expectorante/"
                                className="sf-depth-3"
                              >
                                Inistolin expectorante
                              </a>
                            </li>
                            <li
                              id="menu-554-1"
                              className="last sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/"
                                className="sf-depth-3"
                              >
                                Inistolin antitusivo
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-537-1"
                          className="active-trail middle sf-item-3 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/tos-ninos/remedios/"
                            className="sf-depth-2 active"
                          >
                            Remedios para la tos en niños
                          </a>
                        </li>
                        <li
                          id="menu-487-1"
                          className="middle sf-item-4 sf-depth-2 sf-no-children"
                        >
                          <a href="/tos-ninos/causas/" className="sf-depth-2">
                            Causas
                          </a>
                        </li>
                        <li
                          id="menu-488-1"
                          className="middle sf-item-5 sf-depth-2 sf-total-children-6 sf-parent-children-0 sf-single-children-6 menuparent"
                        >
                          <a
                            href="/tos-ninos/desarrollo-infantil/7-anos/"
                            className="sf-depth-2 menuparent"
                          >
                            Desarrollo infantil
                          </a>
                          <ul>
                            <li
                              id="menu-501-1"
                              className="first sf-item-1 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/7-anos/"
                                className="sf-depth-3"
                              >
                                7 años
                              </a>
                            </li>
                            <li
                              id="menu-498-1"
                              className="middle sf-item-2 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/8-anos/"
                                className="sf-depth-3"
                              >
                                8 años
                              </a>
                            </li>
                            <li
                              id="menu-497-1"
                              className="middle sf-item-3 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/9-anos/"
                                className="sf-depth-3"
                              >
                                9 años
                              </a>
                            </li>
                            <li
                              id="menu-495-1"
                              className="middle sf-item-4 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/10-anos/"
                                className="sf-depth-3"
                              >
                                10 años
                              </a>
                            </li>
                            <li
                              id="menu-493-1"
                              className="middle sf-item-5 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/11-anos/"
                                className="sf-depth-3"
                              >
                                11 años
                              </a>
                            </li>
                            <li
                              id="menu-490-1"
                              className="last sf-item-6 sf-depth-3 sf-no-children"
                            >
                              <a
                                href="/tos-ninos/desarrollo-infantil/12-anos/"
                                className="sf-depth-3"
                              >
                                12 años
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-439-1"
                          className="last sf-item-6 sf-depth-2 sf-no-children"
                        >
                          <a
                            href="/dibujos-para-colorear/"
                            className="sf-depth-2"
                          >
                            Diviértete con Pandolín
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <div id="main">
            <div id="content" className="column" role="main">
              <nav className="breadcrumb" role="navigation">
                <h2 className="element-invisible">Se encuentra usted aquí</h2>
                <ol>
                  <li>
                    <a href="/">Inicio</a> |{" "}
                  </li>
                  <li>
                    <a href="/tos-ninos/">Tos en niños</a> |{" "}
                  </li>
                  <li>Remedios para la tos en niños</li>
                </ol>
              </nav>{" "}
              <a id="main-content" />
              <h1 className="page__title title" id="page-title">
                <span>Remedios para la tos en niños</span>
              </h1>
              <article className="node-15 node node-remedy view-mode-full clearfix">
                <header></header>
                <div className="node-content-wrapper">
                  <div className="node-content">
                    <div className="field-collection-container clearfix">
                      <div className="field field-name-field-recipes-section field-type-field-collection field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <div className="field-collection-view clearfix view-mode-full">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/jarra.png"
                                          width="444"
                                          height="454"
                                          alt
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Remedios caseros
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <strong>
                                            <em>
                                              Es recomendable adoptar medidas
                                              higiénico-dietéticas que mejorarán
                                              la situación del paciente:
                                            </em>
                                          </strong>
                                        </p>
                                        <ul className="list-check">
                                          <li>
                                            <p>
                                              <strong>
                                                Una hidratación adecuada:
                                              </strong>{" "}
                                              el agua es uno de los mejores
                                              remedios mucolíticos que existen y
                                              debemos recordar que cuando no nos
                                              encontramos bien, es importante
                                              hidratarse correctamente, ya que
                                              de lo contrario empeora el estado
                                              de salud. La leche o una sopa
                                              caliente también son adecuados y
                                              disminuirán la irritación de
                                              garganta.
                                            </p>
                                          </li>
                                          <li>
                                            <p>
                                              <strong>
                                                La humidificación del ambiente:
                                              </strong>{" "}
                                              un ambiente con un grado de
                                              humedad adecuado facilita la
                                              respiración, que suele verse
                                              perjudicada en el caso de tos. El
                                              grado de humedad ideal debería
                                              situarse en torno al 40%.
                                            </p>
                                          </li>
                                          <li>
                                            <p>
                                              Otro remedio para calmar la tos en
                                              niños es{" "}
                                              <strong>
                                                levantar la cabecera de la cama
                                              </strong>{" "}
                                              al ir a dormir.
                                            </p>
                                          </li>
                                          <li>
                                            <p>
                                              <strong>
                                                Para evitar contagios
                                              </strong>{" "}
                                              innecesarios al toser, es
                                              necesario taparse la boca con un
                                              pañuelo desechable y tirarlo
                                              después del uso.
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="field-item odd">
                            <div className="field-collection-view clearfix view-mode-full field-collection-view-final">
                              <div className="entity entity-field-collection-item field-collection-item-field-recipes-section clearfix">
                                <div className="content">
                                  <div className="field field-name-field-fc-recipes-section-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/fisoterapia.png"
                                          width="493"
                                          height="454"
                                          alt
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-title field-type-text field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        Fisoterapia
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-body field-type-text-long field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          <strong>
                                            El uso de fitoterapia y de
                                            tratamiento no farmacológico es de
                                            gran ayuda para el paciente
                                          </strong>
                                          , ya que puede servir de gran alivio.
                                          Estos remedios son naturales e
                                          incluyen desde caramelos sin azúcar a
                                          infusiones o aceites para inhalar. El
                                          hecho de que sean naturales no debe
                                          hacernos olvidar que pueden tener
                                          contraindicaciones en pediatría, por
                                          lo que se debe usar adecuadamente
                                          según el rango de edad del
                                          paciente.&nbsp;
                                        </p>
                                        <p>
                                          Además, debemos tener en cuenta que su
                                          efecto es limitado, por eso su uso se
                                          recomienda como complemento a los
                                          productos farmacológicos
                                          específicos.&nbsp;
                                        </p>
                                        <p>
                                          Algunas de las plantas medicinales más
                                          útiles en fitoterapia por sus fines
                                          terapéuticos son:
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-fc-recipes-section-recipe field-type-entityreference field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <article className="node-14 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/eucalipto.png"
                                                    width="399"
                                                    height="258"
                                                    alt="Eucalipto"
                                                    title="Eucalipto"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Eucalipto</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Tiene propiedades
                                                        expectorantes,
                                                        mucolíticas,
                                                        antiespasmódicas y
                                                        antisépticas gracias a
                                                        componentes como el
                                                        eucaliptol, el canfeno,
                                                        el pineno, el limoneno,
                                                        el timol y el geraniol.
                                                        Actúa sobre el epitelio
                                                        bronquial ejerciendo un
                                                        efecto irritante,
                                                        fluidificando y
                                                        aumentando la producción
                                                        de secreciones
                                                        bronquioalveolares.
                                                        Suele ser bien tolerado
                                                        y en muy raras ocasiones
                                                        pueden aparecer náuseas,
                                                        vómitos o diarrea. No se
                                                        recomienda su uso
                                                        durante un período
                                                        prolongado de tiempo o
                                                        en dosis mayores de las
                                                        recomendadas, sobre todo
                                                        el de aceite esencial
                                                        debido a su posible
                                                        neurotoxicidad.&nbsp;
                                                      </p>
                                                      <p>
                                                        <em>
                                                          <strong>
                                                            Este remedio no debe
                                                            administrarse en
                                                            niños menores de 2
                                                            años por vía interna
                                                            o externa, ni en
                                                            menores de 6 años
                                                            por vía interna.
                                                          </strong>
                                                        </em>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item odd">
                                        <article className="node-29 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/drosera.png"
                                                    width="387"
                                                    height="297"
                                                    alt="Drosera"
                                                    title="Drosera"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Drosera</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Tiene propiedades
                                                        antitusivas y
                                                        antiespasmódicas,
                                                        ejerciendo un efecto
                                                        broncodilatador sobre el
                                                        músculo liso bronquial,
                                                        inhibiendo de esta
                                                        manera el reflejo de la
                                                        tos. No se han descrito
                                                        reacciones adversas a
                                                        las dosis terapéuticas
                                                        recomendadas.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item even">
                                        <article className="node-30 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/grindelia.png"
                                                    width="334"
                                                    height="243"
                                                    alt="Grindelia"
                                                    title="Grindelia"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Grindelia</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Destaca en la
                                                        fitoterapia por sus
                                                        propiedades emolientes,
                                                        protectoras y lenitivas
                                                        de las vías
                                                        respiratorias. Los
                                                        indios de América del
                                                        Norte la empezaron a
                                                        utilizar por sus
                                                        propiedades
                                                        expectorantes, pero su
                                                        utilización se ha
                                                        consolidado por su
                                                        efecto balsámico y su
                                                        eficaz acción protectora
                                                        contra los agentes que
                                                        afectan a las vías
                                                        respiratorias
                                                        superiores.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item odd">
                                        <article className="node-31 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/menta.png"
                                                    width="286"
                                                    height="226"
                                                    alt="Menta"
                                                    title="Menta"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Menta</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        El timol, junto con la
                                                        luteolina, el cineol y
                                                        el carvacrol le
                                                        confieren propiedades
                                                        antitusivas que, unidas
                                                        a las antiespasmódicas y
                                                        suavizantes del mentol,
                                                        son muy útiles para
                                                        frenar los ataques de
                                                        tos.&nbsp;
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item even">
                                        <article className="node-32 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/helicriso.png"
                                                    width="365"
                                                    height="251"
                                                    alt="Helicriso"
                                                    title="Helicriso"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Helicriso</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Planta con aceites
                                                        esenciales cuya mezcla
                                                        de sustancias volátiles
                                                        y muy aromáticas,
                                                        desarrolla una eficaz
                                                        actividad balsámica,
                                                        antiinflamatoria y
                                                        antibacteriana,
                                                        protegiendo las mucosas
                                                        y vías respiratorias.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item odd">
                                        <article className="node-33 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/llanten.png"
                                                    width="502"
                                                    height="328"
                                                    alt="Llantén"
                                                    title="Llantén"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Llantén</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Gracias a su contenido
                                                        en mucílagos tiene
                                                        propiedades protectoras,
                                                        fluidificantes y
                                                        descongestionantes de
                                                        las mucosas de las vías
                                                        respiratorias altas.
                                                        Además es muy fácil de
                                                        encontrar ya que crece
                                                        por todas partes.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                      <div className="field-item even">
                                        <article className="node-34 node node-ingredient node-teaser toggle-teaser clearfix">
                                          <header></header>
                                          <div className="right">
                                            <div className="field field-name-field-ingredient-img field-type-image field-label-hidden">
                                              <div className="field-items">
                                                <div className="field-item even">
                                                  <img
                                                    src="/sites/default/files/ingredient/jengibre.png"
                                                    width="568"
                                                    height="384"
                                                    alt="Jengibre"
                                                    title="Jengibre"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="left">
                                            <div className="recipe-wrapper">
                                              <div className="recipe-title">
                                                <h3>Jengibre</h3>
                                                <button className="show-recipe btn btn-blue">
                                                  Ver propiedades
                                                </button>
                                              </div>
                                              <div className="content">
                                                <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                                  <div className="field-items">
                                                    <div className="field-item even">
                                                      <p>
                                                        Esta raíz ha sido
                                                        utilizada desde hace
                                                        cientos de años como
                                                        remedio natural para
                                                        niños por la medicina
                                                        tradicional china. Tiene
                                                        un sin fin de
                                                        propiedades y beneficios
                                                        para la salud y, entre
                                                        todos los beneficios,
                                                        podemos destacar que es
                                                        un gran aliado para
                                                        aliviar la tos, dolores
                                                        de garganta y además
                                                        ayuda a expulsar las
                                                        mucosidades. De hecho,
                                                        últimas investigaciones
                                                        hechas en China e India,
                                                        han demostrado que el
                                                        Jengibre también es
                                                        bueno para luchar contra
                                                        la gripe y reforzar el
                                                        sistema inmunitario.
                                                        <br />
                                                        &nbsp;
                                                        <br />
                                                        <strong>
                                                          <em>
                                                            Una de las mejores
                                                            formas de tomar
                                                            Jengibre es hacerlo
                                                            en infusión, además
                                                            podemos añadir miel
                                                            y limón para que
                                                            este remedio casero
                                                            y natural sea lo más
                                                            eficaz posible.
                                                          </em>
                                                        </strong>
                                                      </p>
                                                      <p>Necesitamos:</p>
                                                      <p>
                                                        ½ cucharadita de raíz de
                                                        jengibre fresca pelada y
                                                        machacada o rallada
                                                        <br />
                                                        1 rodaja de limón
                                                        <br />½ cucharadita de
                                                        miel natural
                                                      </p>
                                                      <p>
                                                        <strong>
                                                          Preparación
                                                        </strong>
                                                        :
                                                      </p>
                                                      <p>
                                                        Vertemos en agua
                                                        hirviendo el jengibre y
                                                        el limón y dejamos
                                                        hervir tapado unos 10’.
                                                        Lo colamos y le añadimos
                                                        la miel.
                                                        <br />
                                                        Se debe tomar tres veces
                                                        al día, la primera
                                                        infusión por la mañana
                                                        en ayunas.
                                                      </p>
                                                      <p>
                                                        Otras formas de tomar
                                                        Jengibre pueden ser
                                                        espolvorear una pizca de
                                                        sal en el jengibre
                                                        fresco, o mezclar media
                                                        cucharadita de jengibre
                                                        en polvo con una
                                                        cucharadita de miel. A
                                                        pesar de tener un sabor
                                                        un tanto raro, es muy
                                                        útil para aliviar la tos
                                                        en los niños.
                                                      </p>
                                                      <p>
                                                        Además de todas estas{" "}
                                                        <strong>
                                                          plantas medicinales
                                                          usadas en la
                                                          fitoterapia
                                                        </strong>
                                                        , no hay que olvidarse
                                                        de la{" "}
                                                        <strong>
                                                          Miel, muy utilizada en
                                                          remedios caseros con
                                                          leche caliente
                                                        </strong>
                                                        , que nos aporta una
                                                        gran ayuda por su acción
                                                        emoliente y protectora,
                                                        ni de los jarabes
                                                        específicos para la tos
                                                        como Inistolín
                                                        Pediátrico Antitusivo e
                                                        Inistolín Pediátrico
                                                        Expectorante.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field field-name-field-highlight-ref field-type-entityreference field-label-hidden">
                      <div className="field-items">
                        <div className="field-item even">
                          <article className="node-78 node node-highlight node-teaser clearfix">
                            <header>
                              <h2 className="node__title node-title">
                                <a href="/jarabe-inistolin/pediatrico-expectorante/">
                                  ¿Y para los más pequeños?
                                </a>
                              </h2>
                            </header>
                            <div className="node-content-wrapper">
                              <div className="node-content">
                                <div className="group-highlight-left field-group-div">
                                  <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/styles/highlight/public/highlight/para-los-mas-pequenos_0.png?itok=fYPe-OJi"
                                          width="302"
                                          height="157"
                                          alt="Iniston Pediátrico para los más pequeños"
                                          title="Iniston Pediátrico para los más pequeños"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="group-highlight-right field-group-div">
                                  <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          Descubre la gama de productos
                                          Inistolin para la tos&nbsp;y la
                                          congestión nasal de los más pequeños.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <a
                                          href="/jarabe-inistolin/pediatrico-expectorante/"
                                          className="btn btn-blue"
                                        >
                                          Ver contenido
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="links inline">
                                  <li className="node-readmore first last">
                                    <a
                                      href="/destacado/y-para-los-mas-pequenos/"
                                      rel="tag"
                                      title="¿Y para los más pequeños?"
                                    >
                                      Leer más
                                      <span className="element-invisible">
                                        {" "}
                                        sobre ¿Y para los más pequeños?
                                      </span>
                                    </a>
                                  </li>
                                </ul>{" "}
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="field-item odd">
                          <article className="node-74 node node-highlight node-teaser clearfix">
                            <header>
                              <h2 className="node__title node-title">
                                <a href="/tos-ninos/tipos-de-tos-ninos/irritativa/">
                                  ¿Qué tipo de tos tiene tu pequeño?
                                </a>
                              </h2>
                            </header>
                            <div className="node-content-wrapper">
                              <div className="node-content">
                                <div className="group-highlight-left field-group-div">
                                  <div className="field field-name-field-highlight-img field-type-image field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <img
                                          src="/sites/default/files/styles/highlight/public/highlight/que-tipo-de-tos-tiene-tu-pequeno.jpg?itok=7nqg4mVK"
                                          width="302"
                                          height="157"
                                          alt
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="group-highlight-right field-group-div">
                                  <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <p>
                                          Aprender a conocer y diferenciar el
                                          tipo de tos de tus hijos es
                                          fundamental para aliviarla. ¡Descubre
                                          cómo hacerlo!
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field field-name-field-highlight-link field-type-link-field field-label-hidden">
                                    <div className="field-items">
                                      <div className="field-item even">
                                        <a
                                          href="/tos-ninos/tipos-de-tos-ninos/irritativa/"
                                          className="btn btn-blue"
                                        >
                                          Ver contenido
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="links inline">
                                  <li className="node-readmore first last">
                                    <a
                                      href="/destacado/que-tipo-de-tos-tiene-tu-pequeno/"
                                      rel="tag"
                                      title="¿Qué tipo de tos tiene tu pequeño?"
                                    >
                                      Leer más
                                      <span className="element-invisible">
                                        {" "}
                                        sobre ¿Qué tipo de tos tiene tu pequeño?
                                      </span>
                                    </a>
                                  </li>
                                </ul>{" "}
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </article>
            </div>
            <div id="navigation"></div>
          </div>
          <footer id="footer" className="region region-footer">
            <div
              id="block-menu-menu-menu-footer"
              className="block block-menu first odd"
              role="navigation"
            >
              <ul className="menu">
                <li className="menu__item is-leaf first leaf">
                  <a href="/contacto/" className="menu__link">
                    Contacto
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-privacidad/" className="menu__link">
                    política de privacidad
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/politica-de-cookies/" className="menu__link">
                    política de cookies
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/aviso-legal/" className="menu__link">
                    Aviso legal
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/acceso-profesionales/" className="menu__link">
                    Acceso profesionales
                  </a>
                </li>
                <li className="menu__item is-leaf leaf">
                  <a href="/preguntas-frecuentes/" className="menu__link">
                    Faqs
                  </a>
                </li>
                <li className="menu__item is-leaf last leaf">
                  <a href="#" id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">
                    Configuración de cookies
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-block-1" className="block block-block last even">
              <p className="rtecenter">
                Publicada por Johnson &amp; Johnson S.A., único responsable de
                su contenido está dirigida a residentes en España.
                <br />
                ©&nbsp;Johnson &amp; Johnson S.A, 2015. Última actualización
                20/12/2014
              </p>
            </div>
          </footer>
        </div>
        <script src="/sites/default/files/js/js_pMpn-aGMsGKgMnAtmxLe-SZAZXUOnXxQqYAoiRN9DJI.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: 'var eu_cookie_compliance_cookie_name = "";'
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction read_cookie(k,r){return(r=RegExp('(^|; )'+encodeURIComponent(k)+'=([^;]*)').exec(document.cookie))?r[2]:null;}\nvar cookie_agreed = read_cookie('cookie-agreed');\nif (cookie_agreed == 2) {\n/* <!--[CDATA[ */\nvar google_conversion_id = 1004307202;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]--> */\n}\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n  <div style="display:inline">\n    <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1004307202/?value=0&amp;guid=ON&amp;script=0">\n  </div>\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
